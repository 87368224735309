import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

import "semantic-ui-css/semantic.min.css"
import { Grid } from "semantic-ui-react"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "MARWEN ABID"
    const subTitle = "Software Engineer"
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        subtitle={subTitle}
      >
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column width={11}>
              <h2>
                Hey people{" "}
                <span role="img" aria-label="wave emoji">
                  👋
                </span>
              </h2>
              <p style={{ fontSize: "17px" }}>
                <p>
                  I'm Marwen <em>/ˈmɑɹwɛn/</em>. I write software and lead
                  engineering teams.
                </p>
                <p>
                  Java developer by day and factotum coder by night. I dabble in
                  Python, NodeJS and my current obsession is Rust.
                </p>
                <p>
                  This is my 12th attempt at starting a blog.
                  <br />
                  My last attempt was almost successful. I could finally make my
                  mind and pick a CSS Framework (Bootstrap). But then I got
                  cocky and decided to deploy things on GKE. Y'all can guess the
                  end to that story...
                </p>
              </p>
            </Grid.Column>
            <Grid.Column width={5}>
              <img
                style={{ margin: 0 }}
                src="./marwen-ok.png"
                alt="Marwen Dev"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
